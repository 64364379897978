<!-- 文件中文名: 订单结算 -->
<template>
  <div class="OrderSettle app-container">
    <el-form ref="posForm" :model="orderForm" label-position="left" size="small"
             style="text-align: left">
      <el-collapse v-model="activeNames" style="width: 80%;margin: 10px auto">
        <!-- 基本信息 -->
        <el-collapse-item :title="$t('Common.basicInfomation')" name="1">
          <div>
            <el-form-item :label="$t('busi.JPorder.memberNO')+(':')">
              <span>{{ orderForm.memberNo }}</span>
            </el-form-item>
            <!--  收获方式 -->
            <el-form-item :label="$t('PoOrder.recType')" required>
              <br>
              <el-radio v-model="orderForm.recType" label="1" @change="setAgentAddr">
                <!--                代办处提货-->
                {{ this.$t('ru.please.goto.agent.toGet') }}
              </el-radio>
              <br>
              <el-radio v-model="orderForm.recType" label="2" @change="setCompanyAddr">
                <!--                分公司提货-->
                {{ this.$t('pick.up.from.branch.office') }}
              </el-radio>
              <br>
              <!--                发到经销商-->
              <el-radio v-model="orderForm.recType" label="3" @change="setMemberAddr">
                {{ this.$t('GoodsToMember') }}
              </el-radio>
              <br>
            </el-form-item>
            <div v-if="orderForm.recType==='1'">
              <!--          代办处提货-->
              <el-form-item :label="$t('ru.please.goto.agent.toGet')" required size="mini"
                            style="width: 100%">
                <el-radio-group v-model="orderForm.recNo" size="mini" style="width: 100%"
                                value-key="agentNo">
                  <div
                      v-for="(item,index) in AgentGetGoodsList"
                      :key="item.agentNo"
                      :class="{ 'under-line': index===AgentGetGoodsList.length-1 }"
                      style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                      @click="agentRadioChange(item)"
                  >
                    <el-radio :label="item.agentNo" class="AgentRadio"
                              style="height: auto;overflow: hidden;">
                      <div style="display: inline-block">
                        <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                          {{ item.companyCode }}&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;&nbsp;
                          ({{ item.agentNo }})&nbsp;&nbsp;&nbsp;{{ item.mobile }}
                        </p>
                        <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                          {{ item.storeAddr }}</p>
                      </div>
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="orderForm.recType==='2'">
              <!--          从分公司提货-->
              <el-form-item :label="$t('pick.up.from.branch.office')" required size="mini"
                            style="width: 100%">
                <el-radio-group v-model="orderForm.outCompanycode" size="mini" style="width: 100%"
                                value-key="agentNo">
                  <div
                      v-for="(item,index) in CompanyGetGoodsList"
                      :key="item.City"
                      :class="{ 'under-line': index===CompanyGetGoodsList.length-1 }"
                      style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                      @click="outCompanyCodeRadioChange(item)"
                  >
                    <el-radio :label="item.companyCode" class="AgentRadio"
                              style="height: auto;overflow: hidden;">
                      <div style="display: inline-block">
                        <p>{{ $t(item.City) }}-{{ $t(item.Country) }}-{{ $t(item.Tel) }}</p>
                        <p>{{ $t(item.Addr) }}</p>
                      </div>
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="orderForm.recType==='3'">
              <!--          地址-->
              <el-form-item :label="$t('mi.address.id')" required size="mini">
                <el-select v-model="orderForm.countryCode" filterable @change="countryChange" popper-class="selectAddr">
                  <el-option
                      v-for="item in countryList"
                      :key="item.id"
                      :label="item.country"
                      :value="item.country"/>
                </el-select>
                <el-select v-model="orderForm.cityCode" filterable @change="cityChange" popper-class="selectAddr">
                  <el-option
                      v-for="item in cityList"
                      :key="item.id"
                      :label="item.province"
                      :value="item.id"/>
                </el-select>
              </el-form-item>
              <!--          详细地址-->
              <el-form-item :label="$t('miAgent.storeAddr')" required size="mini">
                <el-input v-model.trim="orderForm.storeAddr"/>
              </el-form-item>
              <el-form-item :label="$t('busi.JPorder.receiverName')+(':')" required size="mini">
                <el-input v-model="orderForm.name"/>
              </el-form-item>
              <el-form-item :label="$t('fiBankbookTempRecharge.mobile')+(':')" required size="mini">
                <el-input v-model="orderForm.mobile"/>
              </el-form-item>
            </div>
            <!--            备注-->
            <el-form-item :label="$t('busi.common.remark')">
              <el-input v-model="orderForm.remark" :placeholder="$t('ic.remark.null.requie')" :rows="4"
                        maxlength="100"
                        show-word-limit
                        style="min-width: 100px" type="textarea"/>
            </el-form-item>
            <div style="text-align: center">
              <el-button size="mini" v-show="!(activeNames.includes('2'))" @click="goStep">
                {{ $t('button.next') }}
              </el-button>
            </div>
          </div>
        </el-collapse-item>
        <!-- 付款 -->
        <el-collapse-item :title="$t('busi.poMemberOrder.dealType')" name="2">
          <div>
            <el-form-item :label="$t('FiAcInput.orderType')+':'">
              <span v-if="orderType === 18"> {{ $t('poOrder.type.upgrade.order') }} </span>
              <span v-else-if="orderType === 19"> {{ $t('type.order.consume.repeate') }} </span>
              <span v-else-if="orderType === 17"> {{ $t('NewRegisteredFirstOrder') }} </span>
            </el-form-item>
            <!--购货列表-->
            <el-form-item :label="$t('OrderGoodsList')+':'">
              <el-table
                  :data="this.OrderSettlement"
                  class="el-table-border"
                  fit
                  style="width: 100%"
              >
                <el-table-column :label="$t('sr.productName')" align="center" prop="goodsName">
                  <template slot-scope="{ row }">
                    {{ $t(row.goodsName) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Product.Code')" align="center" prop="goodsCode"/>
                <el-table-column :label="$t('biBarCode.quantity')" align="center" prop="quantity"/>
                <el-table-column :label="$t('PV')" align="right">
                  <template slot-scope="{ row }">
                    <p v-show="orderType===18||orderType===17"> {{ row.standardPv }}</p>
                    <p v-show="orderType===19"> {{ row.proPv }}</p>
                  </template>
                </el-table-column>
                <!--                总PV-->
                <el-table-column :label="$t('company.me.totalPV')" align="right">
                  <template slot-scope="{ row }">
                    <p v-show="orderType===18||orderType===17">
                      {{ row.standardPv * row.quantity }}</p>
                    <p v-show="orderType===19"> {{ row.proPv * row.quantity }}</p>
                  </template>
                </el-table-column>
                <!--                价格-->
                <el-table-column :label="$t('pd.price')" align="right">
                  <template slot-scope="{ row }">
                    <p v-show="orderType===18||orderType===17">
                      {{ row.standardPriceB }}{{ $symbol() }}</p>
                    <p v-show="orderType===19" style="font-size: 24px">
                      {{ row.proPriceB }}{{ $symbol() }}</p>
                  </template>
                </el-table-column>
                <!--                积分-->
                <el-table-column
                  v-if="orderType===19"
                  :label="$t('bvPointChange.updatePoint')"
                  align="right"
                >
                  <template slot-scope="{ row }">
                    <p> {{ row.proF000 }}</p>
                  </template>
                </el-table-column>
                <!--总价格-->
                <el-table-column :label="$t('TotalPrice')" align="right">
                  <template slot-scope="{ row }">
                    <p v-show="orderType===18||orderType===17">
                      {{ row.standardPriceB * row.quantity }}{{ $symbol() }}</p>
                    <p v-show="orderType===19" style="font-size: 24px">
                      {{ row.proPriceB * row.quantity }}{{ $symbol() }}</p>
                  </template>
                </el-table-column>
                <!--                总积分-->
                <el-table-column v-if="orderType===19" :label="$t('miagent.fv.total')" align="right">
                  <template slot-scope="{ row }">
                    <p v-show="orderType===19"> {{ row.proF000 * row.quantity }}</p>
                  </template>
                </el-table-column>
              </el-table>
              <el-table
                  :data="tableData"
                  :show-header="false"
                  fit
                  style="width: 100%">
                <el-table-column :label="$t('sr.productName')" align="center" prop="goodsName">
                  <template slot-scope="{ row }">
                    {{ $t(row.goodsName) }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Product.Code')" align="center" prop="goodsCode"/>
                <el-table-column :label="$t('biBarCode.quantity')" align="center" prop="quantity"/>
                <el-table-column :label="$t('PV')" align="right" prop="PV"/>
                <!--                总PV-->
                <el-table-column :label="$t('company.me.totalPV')" align="right">
                  <template slot-scope="{ row }">
                    <p v-if="row.goodsName!=='-'"></p>
                    <p v-else>
                      <span v-show="orderType===18||orderType===17" style="font-size: 16px;font-weight: 600">
                      {{ standardPvSum }}PV
                      </span>
                      <span v-show="orderType===19" style="font-size: 16px;font-weight: 600">
                      {{ proPvSum }}PV
                      </span>
                    </p>
                  </template>
                </el-table-column>
                <!-- - -->
                <el-table-column :label="$t('pd.price')" align="right" prop="price"/>
                <!--                积分-->
                <el-table-column
                    v-if="orderType===19"
                    :label="$t('bvPointChange.updatePoint')"
                    align="right"
                >
                  <template slot-scope="{ row }">
                    <p> {{ row.proF000 }}</p>
                  </template>
                </el-table-column>
                <!--总价格-->
                <el-table-column :label="$t('TotalPrice')" align="right">
                  <template >
                    <p >
                      <span v-show="orderType===18||orderType===17" style="font-size: 16px;font-weight: 600">
                        {{ standardPriceBSum2 }}{{ $symbol() }}
                      </span>
                        <span v-show="orderType===19" style="font-size: 16px;font-weight: 600">
                        {{ proPriceBSum2 }}{{ $symbol() }}
                      </span>
                    </p>
                  </template>
                </el-table-column>
                <!--                总积分-->
                <el-table-column v-if="orderType===19" :label="$t('miagent.fv.total')" align="right">
                  <template >
                    <p >
                      <span style="font-size: 16px;font-weight: 600">
                        {{ proF000Sum }}{{ $t('bvPointChange.updatePoint') }}
                      </span>
                    </p>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
<!--            运费-->
            <el-form-item v-if="orderForm.recType==='3'" :label="$t('shipping.cost')">
              <span style="font-weight: 600;font-size: 16px"> 250 </span>
            </el-form-item>
            <el-form-item :label="$t('Base.currency.wallet')">
              <span style="font-weight: 600;font-size: 16px"> {{ this.B$ }} </span>
            </el-form-item>
            <el-form-item :label="$t('bvPointChange.updatePoint')">
              <span style="font-weight: 600;font-size: 16px"> {{ this.H0 }} </span>
            </el-form-item>
            <!--支付密码-->
            <el-form-item :label="$t('Common.pay.password')+':'" required>
              <el-input v-model="orderForm.payPassword" show-password type="password"></el-input>
            </el-form-item>
            <div class="AppBtn" style="text-align: center">
              <!--立即支付-->
              <el-button size="mini" :loading="doLoading" plain type="primary"
                         @click="submitForm(0)">
                {{ $t('PayImmediately') }}
              </el-button>
              <!--稍后支付-->
              <el-button size="mini" :loading="doLoading" plain type="warning"
                         @click="submitForm(1)">
                {{ $t('btn.pay.later') }}
              </el-button>
              <!--在线支付-->
<!--              <el-button v-show="companyCode==='RUS'" size="mini" :loading="doLoading" plain-->
<!--                         type="primary"-->
<!--                         @click="submitForm(9)">-->
<!--                {{ $t('menu.onlinePayment') }}-->
<!--              </el-button>-->
              <!--在线支付-->
<!--              <el-button v-show="companyCode==='MEX'" size="mini" :loading="doLoading" plain-->
<!--                         type="primary"-->
<!--                         @click="submitForm(9)">-->
<!--                {{ $t('menu.onlinePayment') }}-->
<!--              </el-button>-->
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

    </el-form>
    <div style="margin-bottom: 40px">
      <el-button size="mini" :loading="doLoading" @click="goBack">{{ $t('btn.lastpage') }}</el-button>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex"
import {
  commonGetMiAgents,
  commonGetRecAddrs,
  getPersonalData2,
  memberSaveOrder,
  memberSaveSGOrder,
  userMemberInfo
} from "@/api/rus/api"

export default {
  name: 'OrderSettle',
  directives: {},
  components: {},
  data() {
    return {
      PromotionDisplay: false,//资格码是否显示
      PromotionTime: false,//资格码是否显示
      Promotionyesno: false,//资格码是否显示
      tableData: [{
        goodsName: '-',
        goodsCode: '-',
        quantity: '-',
        PV: '-',
        price: '-',
        proF000: '-',
      }],
      B$: 0,
      F$: 0,
      H0: 0,
      doLoading: false,
      InputMemberNo: null,
      userMemberInfoObj: null,
      AgentInfoObj: null,
      CompanyInfoObj: null,
      regionCodeList: [],
      OrderSettlement: [],
      AgentGetGoodsList: [], // 提货代办处列表
      CompanyGetGoodsList: [],
      companyCodeList: [
        {Country: 'MEX_Country', City: 'MEX_City', Addr: 'MEX_Company_Addr', Tel: 'MEX_Company_Tel', companyCode: 'MEX'} // 墨西哥
      ], // 分公司提货列表
      activeNames: ['1'],
      orderForm: {
        promType: '',
        oppType: [],
        countryCode: '',
        cityCode: '',
        outCompanycode: '',
        storeAddr: '',
        recNo: '',
        payH0: '',
        payAmount: '',
        member: '',
        name: '',
        mobile: '',
        recType: '',
        goodsList: []
      },
      countryList: [],
      cityList: [],
      orderType: '',
      initDict: {
        outCompanyList: 'out.company.list'
      },
    }
  },
  computed: {
    ...mapGetters([
      'companyCode',
      'userCode',
      'username',
      'ckCode',
      'goodsKindCode',
      'goodsKindName',
      "shoppingList"
    ]),
    proF000Sum() {
      let proF000Sum = 0
      this.shoppingList.map(ele => proF000Sum += ele.proF000 * ele.quantity)
      return proF000Sum
    },
    proPvSum() {
      let proPvSum = 0
      this.shoppingList.map(ele => proPvSum += ele.proPv * ele.quantity)
      return proPvSum
    },
    proPriceSum() {
      let proPriceSum = 0
      this.OrderSettlement.map(ele => proPriceSum += ele.proPrice * ele.quantity)
      return proPriceSum
    },
    standardPriceSum() {
      let standardPriceSum = 0
      this.OrderSettlement.map(ele => standardPriceSum += ele.standardPrice * ele.quantity)
      return standardPriceSum
    },
    proPriceBSum() {
      let proPriceBSum = 0
      this.OrderSettlement.map(ele => proPriceBSum += ele.proPriceB * ele.quantity)
      return proPriceBSum
    },
    standardPriceBSum() {
      let standardPriceBSum = 0
      this.OrderSettlement.map(ele => standardPriceBSum += ele.standardPriceB * ele.quantity)
      return standardPriceBSum
    },
    proPriceBSum2() {
      let proPriceBSum = 0
      this.OrderSettlement.map(ele => proPriceBSum += ele.proPriceB * ele.quantity)
      return this.highPrecisionAdd(proPriceBSum, 0)
    },
    standardPriceBSum2() {
      let standardPriceBSum = 0
      this.OrderSettlement.map(ele => standardPriceBSum += ele.standardPriceB * ele.quantity)
      return this.highPrecisionAdd(standardPriceBSum, 0)
    },
    standardPvSum() {
      let standardPvSum = 0
      this.OrderSettlement.map(ele => standardPvSum += ele.standardPv * ele.quantity)
      return standardPvSum
    }
  },
  async created() {
    this.countryList = await this.getAddrList('')
    if (!sessionStorage.getItem("OrderSettlement")) {
      console.log('OrderSettlement不存在!')
      this.$router.go(-1)
    }
    this.doLoading = true

    this.orderType = JSON.parse(sessionStorage.getItem("orderType"))
    this.OrderSettlement = JSON.parse(sessionStorage.getItem("OrderSettlement"))
    console.log(JSON.parse(sessionStorage.getItem('PromotionData')),'123')
    if(sessionStorage.getItem('PromotionData')){
      let PromotionData = JSON.parse(sessionStorage.getItem('PromotionData'))
      PromotionData.forEach(v=>{
        this.OrderSettlement.push(v)
      })

    }
    // 套组选尺码
    if(sessionStorage.getItem('ChooseSizeList')){
      let ChooseSizeList = JSON.parse(sessionStorage.getItem('ChooseSizeList'))
      ChooseSizeList.forEach(v=>{
        this.OrderSettlement.push(v)
      })
    }
    console.log(this.OrderSettlement)
    if (sessionStorage.getItem("tableData")) {
      let a = {}
      a = JSON.parse(sessionStorage.getItem("tableData"))
      this.OrderSettlement.push(a)
    }
    if (sessionStorage.getItem("fivehundredlist")) {
      let a = {}
      a = JSON.parse(sessionStorage.getItem("fivehundredlist"))
      this.OrderSettlement.push(a)
    }
    this.orderForm.memberNo = JSON.parse(sessionStorage.getItem("InputMemberNo"))
    if (!this.OrderSettlement.length) {
      this.$router.push({path: '/shopping-cart'})
    }
    if (sessionStorage.getItem("setproductslist")) {
      let setproductslist = JSON.parse(sessionStorage.getItem('setproductslist'))
      for (let i = 0; i < setproductslist.length; i++) {
        this.OrderSettlement.push(setproductslist[i])
      }
    }
    this.orderForm.orderType = this.orderType
    this.orderForm.member = this.userCode
    await userMemberInfo({memberNo: this.userCode}).then(res => {
      this.userMemberInfoObj = res.data
    }).catch(err => {
      console.warn(err)
      console.warn('userMemberInfo失败')
    })
    await getPersonalData2()
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].acType === 'F$') {
              this.F$ = res.data[i].validBalance
            }
            if (res.data[i].acType === 'B$') {
              this.B$ = res.data[i].validBalance
            }
            if (res.data[i].acType === 'H0') {
              this.H0 = res.data[i].validBalance
            }
            if (res.data[i].acType === 'B1') {
              this.B1 = res.data[i].validBalance
            }
            if (res.data[i].acType === 'B2') {
              this.B2 = res.data[i].validBalance
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    await commonGetMiAgents()
        .then(res => {
          this.AgentGetGoodsList = res.data
          for (let i = 0; i < this.AgentGetGoodsList.length; i++) {
            if (this.AgentGetGoodsList[i].companyCode === 'RUS') {
              this.AgentGetGoodsList[i].companyCode = 'Россия'
            }
            if (this.AgentGetGoodsList[i].companyCode === 'KAZ') {
              this.AgentGetGoodsList[i].companyCode = 'Казахстан'
            }
            if (this.AgentGetGoodsList[i].agentNo === 'GEMCHUG-A') {
              this.AgentGetGoodsList[i].companyCode = 'Korea/Корея'
            }
            if (this.AgentGetGoodsList[i].companyCode === 'TJK') {
              this.AgentGetGoodsList[i].companyCode = 'Tajikistan/Таджикистан'
            }
            if (this.AgentGetGoodsList[i].companyCode === 'MNG') {
              this.AgentGetGoodsList[i].companyCode = 'Mongolia/Монголия'
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    this.doLoading = false
  },
  mounted() {
    this.getCompanyCode()
  },
  destroyed() {
    sessionStorage.setItem('OrderSettlement', '')
  },
  methods: {
    orderDestroyed() {
      sessionStorage.setItem("orderType", '')
      sessionStorage.setItem("InputMemberNo", '')
      sessionStorage.setItem("setproductslist", '')
      sessionStorage.setItem("promType", '')
      sessionStorage.setItem("oppType", '')
    },
    getCompanyCode() {
      for (let a = 0; a < this.companyCodeList.length; a++) {
        if (this.companyCodeList[a].companyCode === this.companyCode) {
          this.CompanyGetGoodsList.push(this.companyCodeList[a])
        }
      }
    },
    cityChange(val) {
      this.orderForm.regionCode = val
    },
    async countryChange(val) {
      this.orderForm.cityCode = ''
      this.orderForm.regionCode = ''
      this.cityList = await this.getAddrList(val)
    },
    getAddrList(countryCode) {
      return new Promise((resolve, reject) => {
        commonGetRecAddrs({country: countryCode}).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    setAgentAddr() {
      this.orderForm.storeAddr = this.AgentInfoObj ? this.AgentInfoObj.storeAddr : ''
      this.orderForm.name = this.AgentInfoObj ? this.AgentInfoObj.name : ''
      this.orderForm.mobile = this.AgentInfoObj ? this.AgentInfoObj.mobile : ''
      this.orderForm.recNo = this.AgentInfoObj ? this.AgentInfoObj.agentNo : ''
    },
    setCompanyAddr() {
      this.orderForm.outCompanycode = this.CompanyInfoObj ? this.CompanyInfoObj.companyCode : ''
      this.orderForm.storeAddr = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Addr).toString() : ''
      this.orderForm.name = ''
      this.orderForm.mobile = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Tel).toString() : ''
      this.orderForm.recNo = ''
    },
    setMemberAddr() {
      this.orderForm.name = this.userMemberInfoObj.accountName
      this.orderForm.mobile = this.userMemberInfoObj.mobile
      this.orderForm.storeAddr = this.userMemberInfoObj.storeAddr
      this.orderForm.recNo = ''
    },
    agentRadioChange(val) {
      this.orderForm.storeAddr = val.storeAddr
      this.orderForm.name = val.name
      this.orderForm.mobile = val.mobile
      this.orderForm.recNo = val.agentNo
      this.AgentInfoObj = val
    },
    outCompanyCodeRadioChange(val) {
      this.orderForm.outCompanycode = val.companyCode
      this.CompanyInfoObj = val
      this.orderForm.storeAddr = this.$t(val.Addr).toString()
      this.orderForm.name = ''
      this.orderForm.mobile = this.$t(val.Tel).toString()
      this.orderForm.recNo = ''
    },
    goStep() {
      if (!this.formCheck()) {
        return false
      }
      this.activeNames = ['2']
    },
    formCheck() {
      if (!this.orderForm.recType) {
        this.$message.error(this.$t('info.select.shipment.type').toString())
        return false
      }
      if (this.orderForm.recType === '1') {
        if (!this.orderForm.recNo) {
          this.$message.error(this.$t('Please.Selec.sa').toString())
          return false
        }
      } else if (this.orderForm.recType === '2') {
        if (!this.orderForm.outCompanycode) {
          this.$message.error(this.$t('Please.Selec.sb').toString())
          return false
        }
      }

      if (!this.orderForm.storeAddr && this.orderForm.recType !== '2') {
        this.$message.error(this.$t('Please.Selec.da').toString())
        return false
      }
      if (!this.orderForm.name && this.orderForm.recType !== '2') {
        this.$message.error(this.$t('info.rec.name.required').toString())
        return false
      }
      if (!this.orderForm.mobile && this.orderForm.recType !== '2') {
        this.$message.error(this.$t('Please.Selec.pn').toString())
        return false
      }
      return true
    },
    OnlinePaymentBtn(payType){
      // payType
      // 0 立即支付
      // 1 稍后支付
      // 9 稍后支付-在线支付
      if (!this.formCheck()) {
        return false
      }
      if(!this.orderForm.payPassword){
        return  this.$message.error(this.$t('register.us.select.password'))
      }
      this.$confirm(this.$t('fi.sure.handle').toString(),
          this.$t('common.tips').toString(),
          {
            confirmButtonText: this.$t('button.confirm').toString(),
            cancelButtonText: this.$t('operation.button.cancel').toString(),
            customClass: 'msgBox',
            type: 'warning'
          }).then(async () => {
        this.orderForm.goodsList = this.OrderSettlement
        this.orderForm.payType = payType === 0 ? 0 : 1  // 立即支付 : 稍后支付
        this.orderForm.selectlevel = this.orderType !== 19 ? JSON.parse(sessionStorage.getItem("selectlevel")) : ''
        this.orderForm.promType = sessionStorage.getItem("promType")
        this.doLoading = true
        try {
          // 根据订单类型决定调用哪个保存订单的方法
          const saveOrderFunc = this.orderType === 17 ? memberSaveSGOrder : memberSaveOrder
          const res = await saveOrderFunc({
            ...this.orderForm,
            isPc: 1
          })
          console.log(JSON.parse(JSON.stringify(res)), '订单完成结果')
          this.doLoading = false
          if (res.success) {
            // 成功保存订单后的公共逻辑
            this.orderDestroyed()
            await this.$store.dispatch('shopping/reSetShoppings');

            // 根据payType的值决定后续操作
            if (payType === 0 || payType === 1) {
              // 线下支付或未知支付方式，跳转到支付成功页面
              await this.$router.push('/pay-success')
            } else if (payType === 9) {
              // 在线支付
              res.data.keyongyue = this.F$
              sessionStorage.setItem('PayOnlineData', JSON.stringify(res.data))
              await this.$router.push('/pay-online')
            }
          }
        } catch (err) {
          this.doLoading = false
          console.warn(err)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('operation.button.cancel').toString()
        })
      })
      this.$router.push({name: 'OnlinePaument'})
    },
    async submitForm(payType) {
      // payType
      // 0 立即支付
      // 1 稍后支付
      // 9 稍后支付-在线支付
      if (!this.formCheck()) {
        return false
      }
      if(!this.orderForm.payPassword){
        return  this.$message.error(this.$t('register.us.select.password'))
      }
      this.$confirm(this.$t('fi.sure.handle').toString(),
          this.$t('common.tips').toString(),
          {
            confirmButtonText: this.$t('button.confirm').toString(),
            cancelButtonText: this.$t('operation.button.cancel').toString(),
            customClass: 'msgBox',
            type: 'warning'
          }).then(async () => {
        this.orderForm.goodsList = this.OrderSettlement
        this.orderForm.payType = payType === 0 ? 0 : 1  // 立即支付 : 稍后支付
        this.orderForm.selectlevel = this.orderType !== 19 ? JSON.parse(sessionStorage.getItem("selectlevel")) : ''
        this.orderForm.promType = sessionStorage.getItem("promType")

        // //----订单金额赋值开始----
        // if (this.orderType === 17 || this.orderType === 18) {
        //   //进行首购/升级单赋值,无积分
        //   //payAmount = 总电子钱包支付金额 不包括运费
        //   this.orderForm.payAmount = this.standardPriceSum
        //   //totalAmount = 总电子钱包金额+运费
        //   this.orderForm.totalAmount = this.highPrecisionAdd(this.standardPriceSum, this.getSendFree)
        // }
        // if (this.orderType === 19) {
        //   //进行重销单赋值,有积分,考虑积分不足情况
        //   //payAmount = 总电子钱包支付金额 不包括运费
        //   this.orderForm.payH0 = this.proF000Sum
        //   this.orderForm.payAmount = this.proPriceSum
        //   if (Number(this.proF000Sum) > Number(this.H0)) {
        //     //积分不足时 补足差额
        //     this.orderForm.payH0 = this.H0
        //     this.orderForm.payAmount = this.highPrecisionReduce(this.highPrecisionAdd(this.proPriceSum, this.proF000Sum), this.H0)
        //   }
        //   //totalAmount = 总电子钱包金额+运费
        //   this.orderForm.totalAmount = this.highPrecisionAdd(this.orderForm.payAmount, this.getSendFree)
        // }
        // // 9 稍后支付-在线支付
        //
        // //奖金钱包金额赋值为0
        // if (payType === 9) {
        //   this.orderForm.totalFb = 0
        // }
        // //----订单金额赋值结束----

        console.log(JSON.parse(JSON.stringify(this.orderForm)), 'this.orderForm')
        this.doLoading = true
        try {
          // 根据订单类型决定调用哪个保存订单的方法
          const saveOrderFunc = this.orderType === 17 ? memberSaveSGOrder : memberSaveOrder
          const res = await saveOrderFunc({
            ...this.orderForm,
            isPc: 1
          })
          console.log(JSON.parse(JSON.stringify(res)), '订单完成结果')
          this.doLoading = false
          if (res.success) {
            // 成功保存订单后的公共逻辑
            this.orderDestroyed()
            await this.$store.dispatch('shopping/reSetShoppings');

            // 根据payType的值决定后续操作
            if (payType === 0 || payType === 1) {
              // 线下支付或未知支付方式，跳转到支付成功页面
              await this.$router.push('/pay-success')
            } else if (payType === 9) {
              // 在线支付
              res.data.keyongyue = this.F$
              sessionStorage.setItem('PayOnlineData', JSON.stringify(res.data))
              this.$router.push({name: 'OnlinePaument'})
              // await openPayfiInput({...res.data}).then(res=>{
              //   sessionStorage.setItem('Recharge_Order_Number', JSON.stringify(res.data))
              //   // await this.$router.push('/pay-online')
              //    this.$router.push({name: 'OnlinePaument'})
              // })
            }
          }
        } catch (err) {
          this.doLoading = false
          console.warn(err)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('operation.button.cancel').toString()
        })
      })
    },
    goBack() {
      this.$router.push({path: '/shopping-cart'})
    }
  }
}

</script>

<style lang="scss">
.selectAddr {
  .el-scrollbar {
    height: 50vh;
  }

  .el-scrollbar__view, .el-select-dropdown__wrap, .el-scrollbar__wrap {
    height: 50vh !important;
    max-height: 50vh !important;
  }
}
</style>
<style lang="scss" scoped>

.radioGroup-radio {
  label {
    display: block;
  }
}

.horizontal-line {
  font-size: 14px;
  color: #a0a0a0;
  text-decoration: line-through;
  padding-left: 12px;
}

.fcr {
  color: red;
}

.AgentRadio ::v-deep .el-radio__input {
  height: 35px;
}

.under-line {
  border-bottom: 1px solid #ddd
}

@media screen and (max-width: 991px) {
  .AgentRadio {
    min-width: 100px !important;
    overflow-x: scroll !important;
  }
  .el-table-border {
    font-size: 10px;
  }
}
</style>
